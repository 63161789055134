<template>
  <div>
    <Header
      title="活動說明"
      :showMemberCenter="false"
      :logo="meta.header_image"
    />

    <div class="wrapper">
      <section>
        <div class="title">活動方式</div>
        <div class="body">
          使用者須先加入Coleman LINE宫方帳號，每成功邀請了位好友加入Coleman
          LINE官方帳號（受邀好友須末曾加入Coleman
          LINE宫方帳號)獲得一次抽獎機會（可重複參加）。
        </div>
      </section>

      <section>
        <div class="title">活動注意事項</div>
        <ul>
          <li>
            中獎名單將以Coleman
            LINE訊息推播通知。若主辦單位於7天内仍無法與中獎者取得聯繫或無法確認中獎資訊，則視為中獎者自動放棄中獎資格，且不再遞補中獎者。
          </li>
          <li>
            獎項以實物為準，恕不挑尺寸及款式，中獎者亦不可要求更改漿項或兌換現金，主辦單位保留更換等值贈品的權利。活動贈品寄送範圍限台灣、金門、澎湖、馬祖。
          </li>
          <li>
            兌獎時間及方式<br />
            (1) 兌講時間：2022/11/23~2022/12/2 23:59<br />
            a 實體獎品：2022/11/23~2022/12/2 23:59<br />
            b 非實體獎品：2022/11/23 18:00 統一匯入會員個人帳號
          </li>
          <li>
            中獎名單將以Coleman
            LINE訊息推播通知。若主辦單位於7天内仍無法與中獎者取得聯繫或無法確認中獎資訊，則視為中獎者自動放棄中獎資格，且不再遞補中獎者。
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/Header";

export default {
  components: { Header },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: calc(100vh - 56px);
  padding: 20px 12px;
  font-size: 14px;
  line-height: 24px;
  color: #636366;
  padding-bottom: 110px;
}
section {
  margin-bottom: 20px;
}
.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #3973ac;
  margin-bottom: 8px;
}

ul {
  li {
    position: relative;
    white-space: pre-wrap;
    padding-left: 10px;
    margin-bottom: 8px;
  }
  li:before {
    content: "-";
    position: absolute;
    color: #3973ac;
    left: 0;
  }
}
</style>
